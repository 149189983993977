import React from 'react';
import { Switch, Route } from 'react-router';
import { SuspenseWithPerf } from 'reactfire';
import Loading from './loading';

import HomePage from '../pages/home-page';
import AboutPage from '../pages/about-page';
import WorkPage from '../pages/work/work-page';
import ContactPage from '../pages/contact-page';
import NotFoundPage from '../pages/not-found-page';
import { AppProps } from '../model';

function AppRoutes(props: AppProps) {
    return (
        <SuspenseWithPerf fallback={<Loading />} traceId={'load-status'}>
            <Switch>
                <Route exact={true} path="/" render={() => <HomePage {...props} />} />
                <Route exact={true} path="/about" render={() => <AboutPage {...props} />} />
                <Route exact={true} path="/work" render={() => <WorkPage {...props} />} />
                <Route exact={true} path="/contact" render={() => <ContactPage {...props} />} />
                <Route render={() => <NotFoundPage {...props}/>} />
            </Switch>
        </SuspenseWithPerf>
    );
}

export default AppRoutes;