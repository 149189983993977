
export function formatText(text: string): string {
  let newText = String(text);

  const email = ['message', 'e-mail', '메시지를', 'メッセージ'];
  email.forEach(txt => {
    if (text.match(`{${txt}}`)) {
      newText = newText
        .replace(
          `{${txt}}`, 
          `<a class="red" href="mailto:contact@littlescrawl.com">${txt}</a>`
        );
    }
  });

  const toWork = ['here', 'ici', '여기를', 'こちら'];
  toWork.forEach(txt => {
    if (text.match(`{${txt}}`)) {
      newText = newText
        .replace(
          `{${txt}}`, 
          `<a class="red" href="/work">${txt}</a>`
        );
    }
  });

  const toContact = ['there', 'la voile', '인터넷에', 'インターネットに'];
  toContact.forEach(txt => {
    if (text.match(`{${txt}}`)) {
      newText = newText
        .replace(
          `{${txt}}`, 
          `<a class="red" href="/contact">${txt}</a>`
        );
    }
  });

  return newText
    .replace(/\{/g, '<span class="underline">')
    .replace(/\}/g, '</span>')
    .replace(/\.\s\s/g, '.<br /><br />')
    .replace(/。\s\s/g, '.<br /><br />');
}

