import React, { useRef, useEffect, useCallback } from 'react';
import { Typing as TypingClass, Config } from '../utils/typing';

import './typing.sass';

interface TitleProps {
  content: any;
  type?: any;
  hideHead?: boolean;
  repeat?: boolean;
  delay?: number;
}

function Typing({content, type: TAG = 'p', hideHead = false, repeat = false, delay = 0}: TitleProps) {
  const timer: any = useRef(null);
  const typeRef: any = useRef(null);

  let items: String[];
  let head: any;

  if (Array.isArray(content)) {
    items = [...content];
  } else {
    // Assumed it is string text
    let separator = '|';
    if (hideHead) {
      separator = '';
    }
    items = [content];
    if (content.includes(separator)) {
      head = content.split(separator)[0];
      items = [` ...${content.split(separator)[1]} `];
    }
  }

  const clearTimers = useCallback((): void => {
    if (timer.current) {
        timer.current.clearAll();
    }
  }, [timer]);

  const playAnimation = useCallback((config: Config): void => {
      clearTimers();
      if (config.element) {
        timer.current = new TypingClass(config, repeat);
      }
  }, [clearTimers, timer, repeat]);

  useEffect(() => {
    const config: Config = {
      element: typeRef.current,
      delay,
      items
    };

    playAnimation(config);
    return () => {
        clearTimers();
    };
  }, [typeRef, delay, items, clearTimers, playAnimation]);

  return (
    <TAG className="typing">
        {head && <><span className="typing-head">{head}:</span> </>}

        <span>
          <span className="red">&#123;</span>
          <span>
            <span ref={typeRef} />
            <span className="cursor" />
          </span>
          <span className="red">&#125;</span>
        </span>
      </TAG>
  );
}

export default Typing;