import React, { useState, useEffect, useRef } from 'react';
import gsap from 'gsap';

import { Lang } from '../../model';

interface Props {
  currentLang: string;
  updateLang: Function;
  data: Lang[];
}

const LANG_IDS = {EN: 'en', FR: 'fr', KO: 'ko', JA: 'ja'};

const ran = (min: number, max: number): number => Math.floor(Math.random() * max) + min;

function Flags({ data, updateLang, currentLang }: Props) {
  const [showModal, toggleShowModal] = useState(false);
  const [isAnimating, updateAnimeState] = useState(false);

  let timer: any;

  const _root: any = useRef(null);

  const displayFlag = (flag: string) => {
    if ( flag === currentLang ) return 'active';
    return 'inactive';
  };

  function handleOnclick(lang: string) {
    if (currentLang !== lang && !isAnimating) {
      updateAnimeState(true);
      gsap.to(_root.current, { duration: .5, rotation: `-=${ran(1000, 3000)}`, transformOrigin: '50% 50%', ease: 'elastic',
        onStart: () => toggleShowModal(false),
        onComplete: () => {
          clearTimeout(timer);
          timer = setTimeout(() => {
            updateAnimeState(false);
            updateLang(lang);
            window.localStorage.setItem('lang', lang);
          }, 100);
        }
      });
    }
  }

  function openModal(): void {
    toggleShowModal(!showModal);
  }

  function renderModal() {
    if (!showModal) return;

    return (
      <div className="modal">
        {data.map(({ id, label }: Lang, i: number) => <div key={i} className={currentLang === id ? 'active' : ''} onClick={() => handleOnclick(id)}>{label}</div>)}
        <div className="modal-background" onClick={() => toggleShowModal(false)} />
      </div>
    );
  }

  const noData = data && data.length === 0;

  useEffect(() => {
    if (!noData) {
      const tl = gsap.timeline();
      tl.set(_root.current, { visibility: 'hidden', scale: 0 })
        .to(_root.current, { duration: .2, y: -500, x: -200, visibility: 'visible', rotation: `-=3000`, transformOrigin: '50% 50%', ease: 'bounce' })
        .to(_root.current, { duration: .8, y: 0, x: 0, rotation: 0, scale: 1.4, transformOrigin: '50% 50%' })
        .to(_root.current, { duration: .25, scale: 1, transformOrigin: '50% 50%' })

        .to(_root.current, { duration: 300, rotation: `+=${ran(360, 1080)}`, transformOrigin: '50% 50%', repeat: -1 });

    }
    return () => {
      clearTimeout(timer);
    };
  }, [noData, timer]);

  if (noData) {
    return <div />;
  }

  return (
    <div className="flags">
      <svg id="Satellite" ref={_root} onClick={openModal} viewBox="0 0 100 100" x="0px" y="0px">
        <g id="SatelliteProps1" >
          <path id="XMLID_45_" style={{ fill: '#A3A59B' }} d="M35.3 25.3l-4 3.1-6.1-10.5 1.4-1z"  />
          <path id="XMLID_47_" style={{ fill: '#A3A59B' }} d="M18.69 8.55l.793-.61 5.66 7.38-.793.608z"  />
          <path id="XMLID_46_" style={{ fill: '#D2D3CF' }} d="M31.9 4.8c2.3 3 .8 10.1-4.6 14.2s-12.6 3.8-14.9.8l19.5-15z"  />
          <circle id="XMLID_48_" style={{ fill: '#A3A59B' }} cx="18.6" cy="7.8" r="1.9"  />
        </g>
        <g id="SatelliteBase" >
          <circle id="XMLID_23_" style={{ fill: '#D2D3CF' }} cx="50.1" cy="50.6" r={35}  />
          <g id="XMLID_31_" >
            <g id="XMLID_24_" >
              <path id="XMLID_26_" style={{ fill: '#D2D3CF' }} d="M85.2 38.8h12.5v23.7H85.2z"  />
              <path id="XMLID_27_" style={{ fill: '#3E3F42' }} d="M85.2 40.6h12.5v20.1H85.2z"  />
            </g>
            <g id="XMLID_30_" >
              <path id="XMLID_29_" style={{ fill: '#D2D3CF' }} d="M2.5 38.8H15v23.7H2.5z"  />
              <path id="XMLID_28_" style={{ fill: '#3E3F42' }} d="M2.5 40.6H15v20.1H2.5z"  />
            </g>
          </g>
        </g>
        <g id="SatelliteProps2" >
          <path id="XMLID_34_" style={{ fill: '#A3A59B' }} d="M61.5 83.4c-.4-5.8 4-10.9 9.9-11.3 2-.1 3.9.3 5.5 1.1-3.6 3.3-9.6 7.1-15.4 10.2z"  />
          <circle id="XMLID_35_" style={{ fill: '#D2D3CF' }} cx="73.3" cy="83.9" r="10.6"  />
          <circle id="XMLID_36_" style={{ fill: '#A3A59B' }} cx="74.8" cy="83.3" r="2.1"  />
        </g>
        <g id="SatelliteFlags" >
          <g id="Flags_1_" style={{ clipPath: 'url(#XMLID_2_)' }} >
            <g id="XMLID_288_" >
              <g id="french_8_" className={displayFlag(LANG_IDS.FR)}>
                <path id="XMLID_325_" style={{ fill: '#FFFFFF' }} d="M82.4 71.9c-21.3.1-43.3.1-64.6 0V29.3c21.4-.1 43.2-.1 64.6 0v42.6z"  />
                <path id="XMLID_324_" style={{ fill: '#001C97' }} d="M39.4 72.1H18.2c-.2 0-.3 0-.3-.2V29.4c0-.3.1-.2.3-.2h21.2c-2.9 8.6-2.9 34.1 0 42.9z"  />
                <path id="XMLID_323_" style={{ fill: '#EF2532' }} d="M60.9 72.1c2.8-8.7 2.8-34.2 0-42.9 7.1 0 14.1-.1 21.2-.1.2 0 .3 0 .3.3V72c0 .3-.1.2-.3.2-7.1-.1-14.1-.1-21.2-.1z"  />
              </g>
              <g id="korean_8_" className={displayFlag(LANG_IDS.KO)}>
                <path id="XMLID_322_" style={{ fill: '#FEFEFE' }} d="M82.4 71.8c-21.2.2-43.3.2-64.6 0V29.4c21.2-.2 43.3-.2 64.6 0v42.4z"  />
                <path id="XMLID_321_" style={{ fill: '#013279' }} d="M61.6 56.3c-.4.8-.9 1.6-1.5 2.3-2.1 2.5-4.8 3.9-8 4.4-4.4.6-8.1-.5-11.3-3.7-2.8-2.8-3.9-6.2-3.5-10.1.1-1.3.5-2.6 1.1-3.8.1-.1.1-.3.2-.4 0 .1 0 .2-.1.4-.3 1-.3 2-.2 3 .5 3.4 3.9 5.8 7.3 5.2 1.9-.3 3.3-1.4 4.5-2.9 1.5-2.2 3.7-3.1 6.3-2.7 3.4.5 5.6 3.4 5.4 6.8.1.4-.1.9-.2 1.5z"  />
                <path id="XMLID_320_" style={{ fill: '#C7032B' }} d="M61.6 56.3c.1-.6.3-1.1.3-1.7.2-3.4-2-6.3-5.4-6.8-2.6-.4-4.7.5-6.3 2.7-1.1 1.6-2.6 2.6-4.5 2.9-3.4.6-6.8-1.8-7.3-5.2-.2-1-.1-2 .2-3 0-.1.1-.2.1-.4.6-1.4 1.6-2.5 2.7-3.5 7-6.2 18-3.7 21.1 5.4 1 3.1.8 6.2-.7 9.2-.1.2-.1.3-.2.4z"  />
                <path id="XMLID_319_" style={{ fill: '#040404' }} d="M38.4 35.6s-.1.1-.1.2c-2.4 2.8-4.8 6.1-7 9.4-.1.2-.2.2-.3.1-.3-.3-.7-.5-1.1-.8-.2-.2-.2-.3 0-.5 1.9-2.9 4-5.8 6.2-8.3.2-.3.5-.6.7-.8.1-.1.2-.2.3-.1.4.2.8.5 1.2.7 0 0 .1 0 .1.1z"  />
                <path id="XMLID_318_" style={{ fill: '#030303' }} d="M31.1 55.8c.2 0 .2.2.3.3 1.9 2.9 4 5.8 6.2 8.4.3.3.5.6.8.9.1.2.2.2 0 .3-.4.2-.7.4-1.1.7-.2.1-.2.1-.4-.1-2.4-2.8-4.8-6-7-9.3-.1-.1-.2-.2 0-.4.3-.1.7-.4 1.2-.8-.1.1-.1.1 0 0z"  />
                <path id="XMLID_317_" style={{ fill: '#030303' }} d="M36.2 34.3l-.2.2c-2.4 2.7-4.8 5.9-6.9 9.1-.1.2-.2.2-.4.1-.3-.3-.7-.5-1-.7-.1-.1-.1-.2 0-.4 2.1-3.2 4.5-6.4 7-9 .1-.1.1-.2.3-.1.4.2.8.5 1.1.7 0 0 .1 0 .1.1z"  />
                <path id="XMLID_316_" style={{ fill: '#030303' }} d="M65.5 33.4c.1 0 .2.1.2.2 1.2 1.3 2.4 2.7 3.6 4.2 1.2 1.5 2.3 3.1 3.3 4.7.1.2.2.3 0 .5-.3.2-.6.4-.9.7-.2.2-.3.1-.4-.1-1.7-2.6-3.6-5.2-5.5-7.5-.5-.6-.9-1.1-1.4-1.6-.1-.1-.2-.2 0-.3.4-.2.8-.4 1.1-.7-.1-.1-.1-.1 0-.1z"  />
                <path id="XMLID_315_" style={{ fill: '#030303' }} d="M26.8 42.3l-.1-.1c-.3-.3-.7-.5-1-.7-.1-.1-.1-.2 0-.3 2-3.2 4.4-6.2 6.9-8.7.1-.1.2-.2.4-.1.3.2.6.4 1 .6.1.1.2.1 0 .3-2.5 2.6-4.9 5.7-7 8.9-.1-.1-.1 0-.2.1z"  />
                <path id="XMLID_314_" style={{ fill: '#030303' }} d="M34.1 68.2l-.1.1c-.3.2-.7.4-1 .6-.2.1-.3.1-.5-.1-1.7-1.7-3.3-3.6-4.8-5.6l-2.1-3c-.1-.2-.1-.3 0-.4.3-.2.6-.4.8-.6.2-.2.3-.2.5.1 1.4 2.1 2.9 4.2 4.4 6.1l2.4 2.7c.2 0 .3 0 .4.1z"  />
                <path id="XMLID_313_" style={{ fill: '#030303' }} d="M63.3 34.7c.1 0 .1.1.2.1 1.1 1.2 2.1 2.5 3.2 3.9.1.2.1.2 0 .3-.4.2-.8.5-1.1.8-.1.1-.2.1-.3-.1-1-1.4-2.1-2.7-3.2-4-.1-.1-.2-.2 0-.3.3-.2.7-.4 1.2-.7z"  />
                <path id="XMLID_312_" style={{ fill: '#030303' }} d="M63.3 66.5l-.1-.1c-.4-.2-.7-.5-1.1-.7-.2-.1-.1-.2 0-.3 1.1-1.2 2.1-2.6 3.2-4 .1-.2.2-.2.3-.1.4.3.8.5 1.1.7.2.1.1.2 0 .3-1 1.4-2.1 2.7-3.2 3.9-.1.3-.1.3-.2.3z"  />
                <path id="XMLID_311_" style={{ fill: '#030303' }} d="M71.6 36.9c.1 0 .1.1.2.1 1 1.3 2 2.7 2.9 4.1.1.1.1.2 0 .3-.3.2-.7.5-1 .8-.1.1-.2 0-.3-.1-.9-1.4-1.9-2.8-2.9-4.1-.1-.1-.1-.2 0-.3.4-.2.7-.5 1-.7 0-.1 0-.1.1-.1z"  />
                <path id="XMLID_310_" style={{ fill: '#040404' }} d="M65.5 67.8c-.4-.2-.8-.5-1.2-.7-.1-.1-.1-.1 0-.3 1.1-1.2 2.1-2.5 3.2-3.8.1-.1.2-.2.3-.1.4.3.7.5 1.1.7.1.1.2.1 0 .3-1.2 1.4-2.3 2.7-3.4 3.9z"  />
                <path id="XMLID_309_" style={{ fill: '#040404' }} d="M32.7 62.9c.1 0 .1.1.2.2 1 1.3 2.1 2.6 3.2 3.8.1.1.1.2 0 .3-.4.2-.8.4-1.1.7-.1.1-.2.1-.3-.1-1.1-1.2-2.2-2.4-3.2-3.8-.1-.1-.1-.2 0-.2.3-.4.7-.6 1.2-.9-.1 0 0 0 0 0z"  />
                <path id="XMLID_308_" style={{ fill: '#030303' }} d="M73.4 58.9c.1.1.1.1 0 0 .5.3.8.6 1.2.8.1.1.1.1 0 .3-.9 1.4-1.9 2.8-2.9 4.1-.1.1-.2.1-.3.1-.3-.2-.7-.5-1.1-.7-.1-.1-.1-.1 0-.3 1-1.4 2-2.8 2.9-4.2.2 0 .2 0 .2-.1z"  />
                <path id="XMLID_307_" style={{ fill: '#040404' }} d="M69.6 63c-.1 0-.1-.1-.2-.1-.4-.2-.7-.5-1.1-.7-.2-.1-.1-.2 0-.3 1-1.3 2-2.7 2.9-4.2.1-.2.2-.2.4-.1.3.3.7.5 1 .8.1.1.1.2 0 .4-.9 1.4-1.9 2.8-2.9 4.1 0-.1-.1 0-.1.1z"  />
                <path id="XMLID_306_" style={{ fill: '#040404' }} d="M28.9 57.4c.1 0 .1.1.2.2.9 1.4 1.9 2.9 2.9 4.2.1.2.1.2 0 .3-.4.2-.7.5-1.1.7-.1.1-.2.1-.3-.1-1-1.4-2-2.7-2.9-4.2-.1-.1-.1-.2 0-.3.3-.1.7-.4 1.2-.8-.1.1 0 .1 0 0z"  />
                <path id="XMLID_305_" style={{ fill: '#030303' }} d="M70.6 56.9c0 .1-.1.1-.1.2-.9 1.4-1.9 2.8-2.9 4.2-.1.2-.2.2-.4.1-.3-.3-.7-.5-1.1-.7-.1-.1-.2-.2-.1-.4 1-1.4 2-2.8 2.9-4.3.1-.2.2-.2.4-.1.4.3.7.6 1.1.8.1.1.2.1.2.2z"  />
                <path id="XMLID_304_" style={{ fill: '#040404' }} d="M70.6 44.3c0 .1-.1.1-.1.2-.4.3-.8.6-1.1.8-.1.1-.2.1-.3-.1-.9-1.5-1.9-2.9-3-4.3-.1-.1-.1-.2 0-.3.4-.3.8-.5 1.2-.8.1-.1.2-.1.3.1 1 1.4 2 2.8 3 4.3v.1z"  />
                <path id="XMLID_303_" style={{ fill: '#030303' }} d="M69.7 64.2l.1.1c.3.2.7.5 1 .7.2.1.1.2 0 .3-1 1.3-2.1 2.4-3.2 3.5-.1.1-.2.2-.4.1-.3-.2-.6-.4-1-.6-.1-.1-.2-.1 0-.3 1.1-1.1 2.1-2.3 3.2-3.6.1 0 .2-.1.3-.2z"  />
                <path id="XMLID_302_" style={{ fill: '#030303' }} d="M67.5 32.2c.1 0 .1.1.2.1 1.1 1.1 2.2 2.3 3.2 3.6.1.1.1.2 0 .3-.3.2-.7.4-1 .7-.1.1-.2.1-.3-.1-1-1.3-2.1-2.6-3.2-3.7-.1-.1-.1-.1 0-.2.2-.2.6-.4 1.1-.7-.1 0-.1 0 0 0z"  />
              </g>
              <g  id="japanese_8_" className={displayFlag(LANG_IDS.JA)}>
                <path id="XMLID_300_" style={{ fill: '#FEFEFE' }} d="M50.1 72.1H18.2c-.3 0-.3-.1-.3-.3V29.4c0-.3.1-.3.3-.3h63.9c.3 0 .3.1.3.3v42.4c0 .3-.1.3-.3.3h-32z"  />
                <path id="XMLID_299_" style={{ fill: '#BD0028' }} d="M65.5 50.6c0 8.6-7.3 14.9-15.4 14.8-8 .1-15.3-6.2-15.3-14.8 0-8.6 7.3-14.9 15.4-14.8 8-.1 15.3 6.2 15.3 14.8z"  />
              </g>
              <g id="english_8_" className={displayFlag(LANG_IDS.EN)}>
                <path id="XMLID_298_" style={{ fill: '#FEFEFE' }} d="M82.4 72c-21.4.1-43.2.1-64.6 0V29.2c21.4-.1 43.2-.1 64.6 0V72z"  />
                <path id="XMLID_297_" style={{ fill: '#D00D24' }} d="M82.4 46.4V55c-5.3.6-15.9.9-27.2.9-.4 0-.4.1-.4.5-.1 6.6-.4 12.4-.8 15.6h-7.8c-.4-3.2-.7-8.9-.8-15.6 0-.4-.1-.5-.5-.5-11.2-.1-21.8-.4-27.1-.9v-8.6c5.3-.5 15.9-.8 27.1-.9.4 0 .4-.1.4-.5.1-6.7.4-12.7.8-15.9h7.8c.4 3.2.7 9.2.8 15.9 0 .4.1.5.5.5 11.3.1 21.9.4 27.2.9z"  />
                <path id="XMLID_296_" style={{ fill: '#00207F' }} d="M25.4 72c1.2-.7 2.3-1.2 3.5-1.9 3.8-2.3 8.2-4.9 13.4-8.2.1 0 .1-.1.3-.2.2 4.4.6 8 1.1 10.4-6.1-.1-12.2-.1-18.3-.1z"  />
                <path id="XMLID_295_" style={{ fill: '#00207F' }} d="M56.6 72.1c.5-2.4.9-5.9 1.1-10.3.1 0 .2 0 .3.1 6.8 4.3 11.8 7.1 16.7 10.2h.2c-6.1-.1-12.2-.1-18.3 0z"  />
                <path id="XMLID_294_" style={{ fill: '#00207F' }} d="M43.7 29.2c-.5 2.3-.9 6.1-1.1 10.6-.1 0-.2-.1-.3-.1-6.8-4.3-11.8-7.3-16.8-10.4h-.1c6.1-.1 12.2-.1 18.3-.1z"  />
                <path id="XMLID_293_" style={{ fill: '#00207F' }} d="M74.9 29.2c-1.8 1.2-3.6 2.2-5.4 3.4-3.3 2-7.1 4.3-11.4 7-.1.1-.2.1-.3.2-.2-4.5-.6-8.3-1.1-10.6h18.2z"  />
                <path id="XMLID_292_" style={{ fill: '#00207F' }} d="M17.9 34.2c3.1 2.2 5.9 4.3 9.3 6.8.7.5 1.4 1 2.1 1.6-4.7.2-8.6.6-11.4 1v-9.4z"  />
                <path id="XMLID_291_" style={{ fill: '#00207F' }} d="M82.4 43.6c-2.8-.4-6.7-.7-11.4-1v-.1c.5-.4 1-.7 1.5-1.1 3.7-2.7 6.7-4.8 9.9-7.1.1 0-.1-.1 0-.1v9.4z"  />
                <path id="XMLID_290_" style={{ fill: '#00207F' }} d="M17.9 57.9c2.8.4 6.8.7 11.4 1v.1c-4.4 3.2-7.7 5.7-11.4 8.3v-9.4z"  />
                <path id="XMLID_289_" style={{ fill: '#00207F' }} d="M82.4 67.2c-3.7-2.6-7-5.1-11.4-8.3v-.1c4.7-.2 8.6-.6 11.4-1v9.4z"  />
              </g>
            </g>
          </g>
        </g>
      </svg>

      {renderModal()}
    </div>
  );
}

export default Flags;

