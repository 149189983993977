import React, { useState, lazy, Suspense } from 'react';

import { AppProps, WorkPageText, skill } from './../../model';

import Typing from './../../components/typing';
import Loading from '../../components/loading';

import WorkBlog from './work-blog';
import WorkProject from './work-project';

import './work-page.sass';

const WorkSkill = lazy(() => import('./work-skill'));

const TABS: string[] = [
    'Projects',
    // 'Skills',
    // 'Blog',
];

const SKILLS: skill[] = [
    // { title: 'PROGRAM', value: 0 },
    { title: 'Flutter', value: 80 },
    { title: 'React', value: 100 },
    { title: 'ReactNative', value: 95 },
    { title: 'Angular', value: 95 },
    { title: 'Ionic', value: 95 },
    { title: 'Design', value: 100 },
    { title: 'NodeJS', value: 85 },
    { title: 'VueJS', value: 80 },

    { title: 'HTML', value: 100 },
    { title: 'Css', value: 100 },

    { title: 'Android', value: 70 },
    { title: 'iOS', value: 20 },

    { title: 'JavaScript', value: 100 },
    { title: 'TypeScript', value: 95 },
    { title: 'Dart', value: 90 },
    { title: 'Python', value: 85 },

    { title: 'NoSQL', value: 90 },
    { title: 'Firebase', value: 95 },

    { title: 'C#', value: 70 },

    { title: 'ZBrush', value: 95 },
    { title: '3Dsmax', value: 95 },

    { title: 'Photoshop', value: 100 },
    { title: 'Illustrator', value: 100 },
    { title: 'InDesign', value: 100 },

    // { title: 'DESIGN', value: 0 },
    { title: '3D Animation', value: 100 },
    { title: 'Concept Art', value: 100 },
    { title: 'Art direction', value: 90 },

    { title: 'Project Managment', value: 100 },
    { title: 'Art consulting', value: 85 },
    { title: 'D3 Graph', value: 95 },

    { title: 'English', value: 100 },
    { title: 'French', value: 100 },
    { title: 'Korean', value: 65 },
    { title: 'Laotian', value: 75 },
    { title: 'Japanese', value: 15 },
];

function ContactPage(props: AppProps) {
    const data: any = props.data['work'] as WorkPageText;
    const [currentTab, updateTab] = useState(TABS[0]);


    function renderTabContent(): any {
        switch(currentTab) {
            case TABS[1]:
                return (
                    <Suspense fallback={<Loading />}>
                        <WorkSkill {...props} data={SKILLS} />
                    </Suspense>
                );

            case TABS[2]:
                return <WorkBlog />;

            case TABS[0]:
                return <WorkProject {...data} />;

            default:
                return null;
        }
    }

    function handleTabButtons(tab: string, i: number): any {
        return (
            <li
                key={i}
                className={currentTab === tab ? 'active' : ''}
                onClick={() => updateTab(tab)}
            >
                {tab}
            </li>
        );
    }

    return (
        <div className="page work-page">
            <Typing content={data.title} type="h1" />

            <div className="work-container">
                <ul className="tab-buttons">{TABS.map(handleTabButtons)}</ul>

                {renderTabContent()}
            </div>
        </div>
    );
}

export default ContactPage;
