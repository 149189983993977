import React, { useEffect, useRef, useState } from 'react';
import gsap from 'gsap';

const ran = (min: number, max: number): number => Math.floor(Math.random() * max) + min;

function Planet() {
  const _root: any = useRef(null);
  const _planet: any = useRef(null);
  const _cil: any = useRef(null);
  const _rot: any = useRef(null);

  const [isAnimating, updateAnimeState] = useState(false);

  useEffect(() => {
    updateAnimeState(true);
    const tl = gsap.timeline();
    const root = _root.current;
    const planet = _planet.current;
    const rot = _rot.current;
    const cil = _cil.current;

    let scale = 1;

    tl.set(root, { visibility: 'visible' })
      .set([root, planet], { scale: 0, transformOrigin: '50% 50%' })
      .set(rot, { rotation: 140, transformOrigin: '50% 200%', ease: 'linear' })
      .set(cil, { rotation: -150, transformOrigin: '50% 200%', ease: 'power2' })
      .to(root, { duration: 1, scale, transformOrigin: '50% 50%', ease: 'elastic.inOut' }, 0.1)
      .to(planet, { duration: 1, scale, transformOrigin: '50% 50%', ease: 'elastic.inOut' }, '-=0.8')
      .to(rot, { duration: 1, rotation: 0, transformOrigin: '50% 200%', ease: 'linear' }, '-=0.8')
      .to(cil, { duration: 1, rotation: 0, transformOrigin: '50% 200%', ease: 'power2' }, '-=1')
      .to(['.shadow-char-in'], { duration: 1, visibility: 'hidden' }, '+=.01')
      .to(['.shadow-char'], { duration: 1, visibility: 'visible', onComplete: () => updateAnimeState(false) }, '-=1');
  }, []);

  function jump(ref: any, shadow: string) {
    if (ref.current && !isAnimating) {
      const tl = gsap.timeline();
      const jumpHeight = ran(75, 200);
      updateAnimeState(true);
      tl.to(ref.current, { duration: .1, scaleY: .85, transformOrigin: '50% 100%', yoyo:true, repeat: 1 })
        .to(ref.current, { duration: jumpHeight * .002, y: -jumpHeight, ease: 'expo', yoyo:true, repeat: 1 })
        .to(ref.current, { duration: .15, y: -(jumpHeight*.05), ease: 'bounce', yoyo:true, repeat: 1,
          onComplete: () => updateAnimeState(false)
        });

      tl.to(shadow, { duration: jumpHeight * .003, scale: .7, transformOrigin: '50% 50%', ease: 'expo', yoyo:true, repeat: 1 }, -.15);
    }
  }

  return (
    <svg id="RootPlanet" ref={_root} viewBox="0 0 800 800" x="0px" y="0px">
      <g id="planet" ref={_planet}>
        <g id="Shadows" >
          <path id="s3" style={{ fill: '#EDF2F2' }} className="shadow3" d="M326.1-15.4l-225 14.3-129 140.9-68 334.3 61 207.8 248.5 205.4 459.6-50.2 161.8-203 61-403.6-93.8-179.1L420-87.1z"  />
          <path id="s2" style={{ fill: '#7A8483' }} className="shadow2" d="M332.8 39.5L132.5 139.9 17.4 363.2l38.6 138 243.1 244 137.7 15.3L672.6 643l110-174.2-15.2-233.1L542.9 89.6z"  />
          <path id="s1" style={{ fill: '#292D2D' }} className="shadow1" d="M635 196l-187-58.9-156 18.1L107 314v170.2l133.8 161 184.8 45.4L635 574.9l58-188.3z"  />
        </g>
        <g id="planetBase" >
          <ellipse id="XMLID_206_" style={{ fill: '#BCC9C6' }} cx="401.9" cy="415.7" rx="243.2" ry="224.1"  />
          <path id="XMLID_205_" style={{ fill: '#7D8279' }} d="M643.4 424.8c0-23.2-3.9-45.6-11.2-66.6l.1-.4-5.7-41.9-20.4-41-26.5-17.6-22.8-1.5c-15.8-12-45.3-26.9-64.1-34.8-30.1-12.7-51.6-15.1-86.8-15.1-28.1 0-46.5-2.8-71.5 5.5-16.7 5.5-41.2 20-56 28.7h-27.6l-41.1 15.6-20.4 25.3-7.8 39.5L186 342c-11.3 25.5-17.6 53.5-17.6 82.8 0 17.6 6.8 49.7 11 66.1 8.6 33 27.6 55.3 50.3 80.6 17.8 19.8 32.3 29.3 56.5 42.3l9.2 22.2 25.4 20.4 45 6.3 40.8-8.7 16.9-11c39.1-2.7 75.6-14 107.1-32.1 36.5-20.8 75.2-51.9 95-87.2 17-30 17.8-62.7 17.8-98.9z"  />
          <g id="XMLID_194_" >
            <path id="XMLID_204_" style={{ fill: '#2C3838' }} d="M255.2 254.8l-27.7 2.1-23.8 22.8-10 20.1v26.4l17.2 16.7 20.2-8.8 34.5-50.6v-22.8z"  />
            <path id="XMLID_203_" style={{ fill: '#2C3838' }} d="M585.6 275.9l-16.2-9.7-11.1 4.8 20.2 28.8 36.7 43.1 6.1-3.8V319l-11.8-20.1z"  />
            <path id="XMLID_202_" style={{ fill: '#2C3838' }} d="M307.2 441.6l-17.8 7.6v20.2l8.9 21.5h30.3L337 458l-14.3-16.4z"  />
            <path id="XMLID_201_" style={{ fill: '#2C3838' }} d="M307.2 619.9l6 19.9 15.4 3.8h32.9v-16.1L328.6 611z"  />
            <path id="XMLID_200_" style={{ fill: '#2C3838' }} d="M506.6 571.9h-10.2l-8.3 17.7 9.2 21.4h14.6l9.5-19.6v-14.5z"  />
            <path id="XMLID_199_" style={{ fill: '#2C3838' }} d="M345.3 415.7l-8.3 9.8 8.3 8.5h16.2v-13.9l-6.7-6.3z"  />
            <path id="XMLID_198_" style={{ fill: '#2C3838' }} d="M532 279.7h-7.2l-5.9 5.5 6.5 9.3h13.7v-7.4z"  />
            <path id="XMLID_197_" style={{ fill: '#2C3838' }} d="M615.6 358.7l1.3-3.8-2.2-4.4-6.8 1.3-2.4 7.3 4.4 1.7z"  />
            <path id="XMLID_196_" style={{ fill: '#2C3838' }} d="M295.7 315.3l11-11.3-4.8-13.3-11.4-11-21.7 10.8 1.8 24.6 14.1 5.7z"  />
            <path id="XMLID_195_" style={{ fill: '#2C3838' }} d="M274.6 345.8l3.7-9.4-8-2.6-11.5 5.7 3.2 9.1 6.3 1.8z"  />
          </g>
        </g>
      </g>
      <g id="Characters">
        <ellipse id="Shadow_rot" className="shadow-char" style={{ fill: '#2C3838' }} cx="480.9" cy="273.8" rx="60.3" ry="4.5"  />
        <g id="Rot" ref={_rot} onClick={() => jump(_rot, '#Shadow_rot')}>
          <ellipse id="Shadow_rot_intro" className="shadow-char-in" style={{ fill: '#2C3838' }} cx="480.9" cy="273.8" rx="60.3" ry="4.5"  />
          <g id="Base_x5F_rot" >
            <g id="Antenna_2_" >
              <g id="RightAntenna_2_" >
                <path id="XMLID_145_" style={{ fill: '#F1EAB2' }} d="M465.2 152.3h-7.4c2.3 0 4.2 4.3 4.2 9.5 0 5.3-1.9 9.5-4.2 9.5h7.4c2.3 0 4.2-4.3 4.2-9.5s-1.9-9.5-4.2-9.5z"  />
                <path id="XMLID_142_" style={{ fill: '#E1DA97' }} d="M455.1 143.8h-12.2v71.5h26l1.1-64.6-14.9-6.9zm8 27.6c-2.3 0-4.2-4.3-4.2-9.5 0-5.3 1.9-9.5 4.2-9.5s4.2 4.3 4.2 9.5-1.9 9.5-4.2 9.5z"  />
                <path id="XMLID_141_" style={{ fill: '#F1EAB2' }} d="M455.6 143.8h-12.7v71.5l13.2-.5z"  />
              </g>
              <g id="LeftAntenna_2_" >
                <path id="XMLID_140_" style={{ fill: '#F1EAB2' }} d="M516.5 152.3h-7.4c2.3 0 4.2 4.3 4.2 9.5 0 5.3-1.9 9.5-4.2 9.5h7.4c2.3 0 4.2-4.3 4.2-9.5.1-5.2-1.8-9.5-4.2-9.5z"  />
                <path id="XMLID_137_" style={{ fill: '#E1DA97' }} d="M508.1 143.8h-12.2v71.5h25l1.1-64.6-13.9-6.9zm7.9 27.6c-.2 0-.4 0-.5-.1-.2 0-.4.1-.5.1-2.3 0-3.2-4.3-3.2-9.5 0-5.3.8-9.5 3.2-9.5.2 0 .4 0 .5.1.2 0 .4-.1.5-.1 2.3 0 4.2 4.3 4.2 9.5.1 5.2-1.8 9.5-4.2 9.5z"  />
                <path id="XMLID_136_" style={{ fill: '#F1EAB2' }} d="M508.6 143.8h-14.9v71.5l15.4-.5z"  />
              </g>
            </g>
            <g  id="Feet_2_" >
              <path id="LeftFoot_2_" style={{ fill: '#111111' }} d="M522.7 262.2h-10.1c-2.4 0-4.9 1.9-4.9 4.4l.4 3.7c.7 2.4 2.5 4.3 4.5 4.3l7.7.1c2.4 0 5-1.9 5-4.4l.2-4.1c0-2.6-.4-4-2.8-4z"  />
              <path id="RightFoot_2_" style={{ fill: '#111111' }} d="M454.8 261.7h-7.1c-2.4 0-4 2-4 4.4l.4 3.5c0 2.4 2 4.4 4.4 4.4h6c2.4 0 4.6-2 4.6-4.4v-3.5c.1-2.4-1.9-4.4-4.3-4.4z"  />
            </g>
            <g id="RightArm_4_" >
              <g id="XMLID_128_" >
                <path id="XMLID_135_" d="M437.3 252.5L421.7 263l4.2 4.5 15.4-9.7z"  />
                <g id="XMLID_129_" >
                  <path id="XMLID_134_" d="M417.8 259.1l-3.5 4 6.4 1.9 2.4-3.4z"  />
                  <path id="XMLID_133_" d="M427.4 265.6l-5.3 2.1.8 5.3 5.2-2.1z"  />
                  <g id="XMLID_130_" >
                    <path id="XMLID_132_" style={{ fill: '#484445' }} d="M425.5 261.4l-.8-.3c-2.1-.6-4.3.6-5 2.7-.6 2.1.6 4.3 2.7 5l.8.3c2.1.6 4.3-.6 5-2.7.5-2.2-.6-4.4-2.7-5z"  />
                    <ellipse id="XMLID_131_" style={{ fill: '#111111' }} cx="423.9" cy="265.2" rx="2.2" ry="2.4"  />
                  </g>
                </g>
              </g>
              <g id="XMLID_125_" >
                <path id="XMLID_127_" style={{ fill: '#484445' }} d="M440.9 251.3l-.8-.3c-2.1-.6-4.3.6-5 2.7-.6 2.1.6 4.3 2.7 5l.8.3c2.1.6 4.3-.6 5-2.7.6-2.2-.6-4.4-2.7-5z"  />
                <ellipse id="XMLID_126_" style={{ fill: '#111111' }} cx="438.3" cy="254.8" rx="2.2" ry="2.4"  />
              </g>
            </g>
            <g  id="Body_4_" >
              <g id="Group_8_1_" >
                <g id="Group_9_1_" >
                  <path id="XMLID_124_" style={{ fill: '#030303' }} d="M434.3 213.4v4.2l3.5 1.1 1.1-3.5z"  />
                  <path id="XMLID_123_" style={{ fill: '#484445' }} d="M434.3 213.2h3.5v4.1h-3.5z"  />
                </g>
                <g id="Group_9_copy_1_" >
                  <path id="XMLID_122_" style={{ fill: '#030303' }} d="M434.3 219.7v4.2l4.2.4 1.1-3.5z"  />
                  <path id="XMLID_121_" style={{ fill: '#484445' }} d="M434.3 219.5h3.5v4.1h-3.5z"  />
                </g>
              </g>
              <g id="XMLID_118_" >
                <path id="XMLID_120_" style={{ fill: '#484445' }} d="M519.2 206.9l-81.2 1.5-1.7 2.9.7 53.5 2.8 2.2 79.4-.4 11-4 1.4-50.4z"  />
                <path id="XMLID_119_" style={{ fill: '#111111' }} d="M519.2 206.7l.6 2.8v54.2l-.8 2.8 11.3-3.9.2-7.4.9-43.1z"  />
              </g>
              <g id="Group_6_1_" >
                <g id="Group_7_copy_1_" >
                  <path id="XMLID_117_" style={{ fill: '#484445' }} d="M524 218.2l-2.8.5v3.7l10.2 3.2 1.3-.9v-2.8z"  />
                  <path id="XMLID_116_" style={{ fill: '#111111' }} d="M524 218.2v3.7l8.7 2.8v-2.8z"  />
                </g>
                <g id="Group_7_1_" >
                  <path id="XMLID_115_" style={{ fill: '#484445' }} d="M524 211.3l-2.8.4v3.3l10.4 3.7 1.1-.3V215z"  />
                  <path id="XMLID_114_" style={{ fill: '#111111' }} d="M524 211.3v3.2l8.7 3.7V215z"  />
                </g>
              </g>
            </g>
            <g id="LeftArm_4_" >
              <g id="XMLID_107_" >
                <path id="XMLID_113_" d="M529.8 254.6l-5.3 4 11.9 13.7 5.3-3.1z"  />
                <g id="XMLID_108_" >
                  <path id="XMLID_112_" d="M546.6 266.6l-5.8.8 1.4 4h6.6z"  />
                  <path id="XMLID_111_" d="M535.5 270.1l-2.2 4.8 4.4 3.6 2.3-4.9z"  />
                  <path id="XMLID_110_" style={{ fill: '#484445' }} d="M539.5 266.6h-.9c-2.2 0-4 1.8-4 4s1.8 4 4 4h.9c2.2 0 4-1.8 4-4 0-2.3-1.8-4-4-4z"  />
                  <ellipse id="XMLID_109_" style={{ fill: '#111111' }} cx="538.8" cy="271.1" rx="2.2" ry="2.4"  />
                </g>
              </g>
              <g id="XMLID_104_" >
                <path id="XMLID_106_" style={{ fill: '#484445' }} d="M527.6 252.4h-.9c-2.2 0-4 1.8-4 4s1.8 4 4 4h.9c2.2 0 4-1.8 4-4s-1.8-4-4-4z"  />
                <ellipse id="XMLID_105_" style={{ fill: '#111111' }} cx="527.7" cy="256.5" rx="2.2" ry="2.4"  />
              </g>
            </g>
            <g id="Eyes_4_" >
              <g id="RotRightEye" >
                <circle id="XMLID_103_" cx="452.6" cy={252} r="9.7"  />
                <circle id="XMLID_102_" style={{ fill: '#F79838' }} cx="453.5" cy={252} r={8}  />
                <circle id="XMLID_101_" style={{ fill: 'none', stroke: '#484445', strokeMiterlimit: 10 }} cx="453.4" cy="251.8" r="8.8"  />
                <circle id="XMLID_100_" style={{ fill: '#FCC792' }} cx="454.6" cy="251.5" r="4.6"  />
              </g>
              <g id="RotLeftEye" >
                <circle id="XMLID_99_" cx="503.4" cy={252} r="9.7"  />
                <circle id="RotLeftEyeFill" cx="502.5" cy={252} r={8}  />
                <circle id="XMLID_97_" style={{ fill: 'none', stroke: '#484445', strokeMiterlimit: 10 }} cx="502.6" cy="252.2" r="8.8"  />
                <circle id="XMLID_96_" style={{ fill: '#FCC792' }} cx="501.8" cy="251.9" r="4.6"  />
              </g>
            </g>
            <path id="Mouth_4_" d="M469.4 253.9h17.8v2.4h-17.8z"  />
            <g id="Cap_2_" >
              <g id="XMLID_93_" >
                <path id="XMLID_95_" style={{ fill: '#F0DB3E' }} d="M481.6 191.5c-4.5 0-9.1 4.8-9.1 4.8v15.4H491v-15.4c-.1-.1-4.7-4.8-9.4-4.8z"  />
                <path id="XMLID_94_" style={{ fill: '#D7CA69' }} d="M487.2 193.4c-2-1.2-3.9-1.7-3.9-1.7l2.8 7.8.4 15.4 4.4-2.6v-16.1s-1.8-1.7-3.7-2.8z"  />
              </g>
              <g id="XMLID_90_" >
                <path id="XMLID_92_" style={{ fill: '#F0DC3E' }} d="M458.7 207.8v19.8h34.2v-20.5z"  />
                <text id="XMLID_91_" transform="translate(474.623 224.552)" style={{fill: '#323330'}}>
                  JS
                </text>
              </g>
            </g>
          </g>
        </g>
        <ellipse id="Shadow_cil" className="shadow-char" style={{ fill: '#2C3838' }} cx="337.5" cy={274} rx="60.3" ry="4.5"  />
        <g id="Cil" ref={_cil} onClick={() => jump(_cil, '#Shadow_cil')}>
          <ellipse id="Shadow_cil_intro" className="shadow-char-in" style={{ fill: '#2C3838' }} cx="337.5" cy={274} rx="60.3" ry="4.5"  />
          <g id="Legs_1_" >
            <path id="Left_x10_Leg_1_" style={{ fill: '#9D7157' }} d="M366.5 267.6c.6 1.5.4 6.1.4 6.1s10.9.7 11.5 0c.4-.5 1-5 1.3-7.9 0 0 .7-5.2 0-6s-10.6-2.3-11.9-.6c-1.4 1.6-1.3 8.4-1.3 8.4z"  />
            <path id="RightLeg_1_" style={{ fill: '#9D7157' }} d="M307.9 267.1c-.7 1.2-.7 7.1-.7 7.1s-11.7.2-12.3-.5c-.4-.5-.6-6.5-.9-8.7 0 0 .5-7.2 2.6-7.5s2.6-.3 2.6-.3 6-1 7.6 0c1.5.8 1.1 9.9 1.1 9.9z"  />
          </g>
          <path id="XMLID_68_" style={{ fill: '#9D7157' }} d="M366.5 267.6c-10.1.4-23.8.9-27.5.9-4.1 0-20.9-.7-31.3-1.3-9.3-.5-13.2-1.4-13.9-2.2-.7-.8-2.5-7.9-3.2-12.4-.7-4.6-.3-10.8.5-10.8h89.6l1.5 2.8s-.3 8.9-.4 10.8-.6 7.2-1.1 8.6c-.5 1.3-.5 1.4-1.2 1.8-.4.4-5.3.9-13 1.8z"  />
          <path id="XMLID_64_" style={{ fill: '#B28F78' }} d="M368.8 265.9c-.4-1.2-9.3-2.4-27.2-2.2-17.9.2-33 1.7-33.7 2.4-.7.7-1.1.7-.4 1.1.8.5 15.6 1.4 30.2 1.5 14.4.1 29.2-1 29.2-1s2.4-.7 1.9-1.8z"  />
          <g id="Arms_1_" >
            <path id="RightArm_2_" style={{ fill: '#9D7157' }} d="M288.4 252.1c-3.2 1.7-8.6 6.9-9.8 8.4-1.7 2.1-4 4.9-3.1 6.3 1 1.4 5.2-1.8 6.1-2.4 1.3-.8 6.3-2.3 9.8-1.7l3.5.7s4-1.8 4.2-4.6l.3-2.8s-1.1-3.6-3.1-4.9c-1.8-1.3-4.7-.6-7.9 1z"  />
            <path id="LeftArm_2_" style={{ fill: '#9D7157' }} d="M382.1 255.3c2.9 2.3 6.1 5 7 6.1 1.7 2.1 4 4.9 3 6.3s-4-1.6-6.1-2.4c-.6-.2-2.7-.9-5-1.5 0 0-4.3-2.6-5.5-4.3-.9-1.3-1.3-4.7.5-6 1.8-1.3 5.3 1.1 6.1 1.8z"  />
          </g>
          <g id="Eyes_2_" >
            <g id="RightEye_2_" >
              <circle id="XMLID_63_" cx="366.7" cy="252.2" r="9.7"  />
              <circle id="XMLID_62_" style={{ fill: '#F7F8F9' }} cx="364.5" cy="246.3" r="4.6"  />
            </g>
            <g id="LeftEye_2_" >
              <circle id="XMLID_61_" cx="315.9" cy="252.3" r="9.7"  />
              <circle id="XMLID_60_" style={{ fill: '#F7F8F9' }} cx="312.5" cy="246.5" r="4.6"  />
            </g>
            <g id="CilEyesClose" >
              <circle id="XMLID_2_" style={{ fill: '#9D7157' }} cx="366.7" cy="232.2" r="11.5"  />
              <circle id="XMLID_1_" style={{ fill: '#9D7157' }} cx="315.9" cy="232.3" r="11.4"  />
            </g>
          </g>
          <path id="Mouth_2_" style={{ fill: '#622F14' }} d="M341.3 253.5l-4.9 1.6.4 1 4.5-1.3 4.1 1.1.4-.8z"  />
          <path id="XMLID_59_" style={{ fill: '#EFECDF' }} d="M393.6 168.4c-.6-3.8-7.2-17.8-13.5-21.3 4 3.3 8.5 19.3 9.1 25 .1.6.1 1.3.1 2.1-2.6-11.3-9.6-24.7-27.1-32.3-30.6-13.4-55.1.1-63.3 2.2-8.2 2.1-41-1.3-43.3-10.2s-5 23.6 1.7 39.8c4.6 11 11 31.3 30.2 38.1l8.8 5.8H372l9.4-4.3s7.5-12.6 9-21.4.1-.3.1-.3 3.8-19.4 3.1-23.2z"  />
          <path id="XMLID_54_" style={{ fill: '#EF5253' }} d="M352.7 142.9c8.5 1.6 29.4 14.6 29.4 14.6s-11.7-17.5-34.6-21.1-49.4 7.5-54.4 7.8-17.9.2-26.3-2.8c-7.2-2.6-10.9-7.2-11.8-8.4v-.7s-1.3-.2-1.5 6.5c-.1 3.8-.5 9.3-.3 13.6-.8-3.2-1.3-9-1.5-14.5-.1.2-.1.5-.2.8v-.6c-.1 1.1-.2 2.1-.3 3.1-.3 3.5-.2 8.9.7 13.4.1.4.2.9.3 1.3 1 4.5 2.4 6.7 2.5 7.8.6 7.5 4.1 14.1 4.1 14.1l7.4 15.2 3.5-1.3 3-8.5s-.1-.1-.2-.3c.6-.2 1.6-.5 3.3-1 3.3-1 15.5 5.1 18.3 5.2-1.4-4.4-14.4-7.3-17.2-9.6-2.8-2.3-10.2-10-11.5-11.7s-2.8-5.5.9-3.5 15.8.7 17.8 0 12.4-1.3 24.8-1.3c-2.3-1.4-14.5-3.1-25-3 11.7-7.1 38.7-2.4 43.5-1.7 4.9.7 18.1 11.3 22.8 16.8-.4-7.2-11.2-16.1-15.7-20.5-4.4-4.4-20.9-4.5-28.5-5.2 10.4-7.4 39.7-5.8 46.7-4.5z"  />
          <path id="XMLID_38_" style={{ fill: '#CDBD7E' }} d="M384.3 212.9c0-.6-.7-1.1-.7-1.1s-21.4-2.6-43.6-2.9c-24.2-.3-52.9 2.7-52.9 2.7s-.9.5-.9 1.3c0 .4.9.9.9 1.5 0 5.8 2.4 22.9 2.4 28.4 0 .5-.9.8-.9 1.2 0 .8.9 1.3.9 1.3s25.8 1.9 49.7 1.9c22.6 0 43.3-1.9 43.3-1.9s.5-.3.5-.8c0-.2-.5-.5-.5-.8 0-5.6 1.1-29.6 1.1-29.6s.7-.6.7-1.2z"  />
        </g>
      </g>
    </svg>
  );
}

export default Planet;
