export interface Config {
  element: any;
  delay: number;
  items: String[];
}

// @mutate function
export class Typing {
  timer: any;

  timerInner: any;
  timerInner2: any;
  deleteInterval: any;

  repeat: boolean = true;


  constructor(config: Config, repeat: boolean = true) {

    this.repeat = repeat;
    this.timer = this.createTimeout(config);
  }

  createTimeout(config: Config) {
    const context = this;
    return setTimeout(() => {
      let sentence: number = 0;
      let currentChar: number = 0;

      config.element.innerHTML = '';

      function type() {
          if (sentence >= config.items.length) {
            sentence = 0;
          }

          const chars = config.items[sentence].split('');
          context.timerInner = setTimeout(() => {
              if (currentChar >= chars.length) {

                if (sentence >= config.items.length -1) {
                  if (!context.repeat) {
                    context.clearAll();
                    return;
                  }
                }

                context.timerInner2 = setTimeout(() => {
                      sentence++;

                      context.deleteInterval = setInterval(() => {
                          config.element.innerHTML = config.element.innerHTML.substr(0, currentChar - 1);
                          currentChar--;

                          if (currentChar === 0) {
                              clearInterval(context.deleteInterval);
                              type();
                          }
                      }, context.actionSpeed(20, 50));
                  }, 1000);
                  return;
              }

              config.element.innerHTML += chars[currentChar];
              currentChar++;

              type();
          }, context.actionSpeed(10, 100));
      }

      type();
    }, config.delay);
  }

  actionSpeed(min: number, max: number): number {
    return Math.floor(Math.random() * max) + min;
  }

  timerIsActive() {
    return this.timer === undefined || this.timer === null;
  }

  clearAll(): void {
    clearTimeout(this.timer);
    clearTimeout(this.timerInner);
    clearTimeout(this.timerInner2);
    clearInterval(this.deleteInterval);
  }
}