import React from 'react';
import { AppProps, ContactPageTexts } from '../model';
import Typing from '../components/typing';

import './contact-page.sass';

const CONTACT_LINKS = [
    { url: 'https://www.2dimensions.com/a/saanglao', id: 'flareNima', icon: 'fa fa-burn' },
    { url: 'https://github.com/Saanglao', id: 'github', icon: 'fab fa-github' },
    { url: 'https://fr.linkedin.com/in/phetsarath-phommarinh-62405239', id: 'linkedIn', icon: 'fab fa-linkedin' },
    { url: 'https://twitter.com/Saanglao', id: 'twitter', icon: 'fab fa-twitter' },
];

function ContactPage(props: AppProps) {
    const data: any = props.data['contact'] as ContactPageTexts;

    function getLabel(id: string): string {
        return data[id];
    }

    return (
        <div className="page contact-page">
            <Typing content={data.title} type="h1" />

            <ul>
                {CONTACT_LINKS.map(({ url, id, icon }, i) => <li key={i}><a href={url} target="_blank" rel="noopener noreferrer"><i className={icon}/>{getLabel(id)}</a></li>)}
            </ul>
        </div>
    );
}

export default ContactPage;
