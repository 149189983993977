import React from 'react';

// style={{ enableBackground: 'new 0 0 800 800' }}
export default () => (
  <div id="Frontground">
    <svg viewBox="0 0 800 800" x="0px" y="0px">
      <defs>
        <filter id="blur">
          <feGaussianBlur in="SourceGraphic" stdDeviation="2,2" />
        </filter>
      </defs>
      <path id="XMLID_21_" style={{ fill: '#615E53' }} d="M189.8 389.6l-10.5-8.4-11.7-.1-7.5 9.4 1.7 8.9 2.9 5.8 13.7 3.3 8.5-6.6z" />
      <path id="XMLID_20_" style={{ fill: '#615E53' }} d="M44.4 479l3.5-6.3-1-6.1-5.6-3.1-4.5 1.7-2.8 2-.5 7.5 4.2 3.9z" />
      <path id="XMLID_19_" style={{ fill: '#615E53' }} d="M259.7 615.9l-3.3-2.7h-3.7l-2.3 2.9.5 2.8.8 1.9 4.4 1 2.6-2z" />
      <path id="XMLID_18_" style={{ fill: '#615E53' }} d="M248.4 1148.4l-1-.8h-1l-.7.8v.9l.4.5 1.3.3.7-.6z" />
      <path id="XMLID_17_" style={{ fill: '#615E53' }} d="M63.8 581.2l-1-.8h-1l-.7.9.1.8.3.6 1.2.3.8-.6z" />
      <path id="XMLID_16_" style={{ fill: '#615E53' }} d="M69.9 1074.4l5.6 4.5h6.3l4-5-1-4.7-1.5-3.1-7.3-1.8-4.5 3.5z" />
      <path id="XMLID_15_" style={{ fill: '#615E53' }} d="M947.4 588.3l-3.5 6.3 1 6.1 5.7 3.2 4.5-1.8 2.7-2 .6-7.5-4.2-3.9z" />
      <path id="XMLID_14_" style={{ fill: '#615E53' }} d="M835.6 966.4l3.3 2.7h3.5l2.5-3-.5-2.8-1-1.8-4.2-1-2.8 2z" />
      <path id="XMLID_13_" style={{ fill: '#615E53' }} d="M838.1 747.1l1 .8h1.1l.7-.9-.2-.8-.3-.5-1.2-.3-.8.6z" />
      <path id="XMLID_12_" style={{ fill: '#615E53' }} d="M188.7 526.7l1 .8h1l.7-.9-.1-.8-.3-.5-1.3-.3-.7.6z" />
      <path id="XMLID_11_" style={{ fill: '#615E53' }} d="M760.2 1229.4l14.8-3.5 8.1-10.4-3.1-13.2-9.1-4.6-7.1-1.4-12.4 9.9-.1 12z" />
      <path id="XMLID_10_" style={{ fill: '#615E53' }} d="M953 844.2l3.4-6.3-1-6.2-5.6-3.1-4.5 1.7-2.9 2.1-.4 7.5 4.2 3.9z" />
      <path id="XMLID_9_" style={{ fill: '#615E53' }} d="M156.8 518.7l3.3 2.6 3.7.1 2.4-3-.6-2.8-.9-1.8-4.3-1.1-2.7 2.1z" />
      <path id="XMLID_5_" style={{ fill: '#615E53' }} d="M-27.8 545.3l-.9-.8h-1.1l-.7.9.2.8.2.5 1.3.3.8-.6z" />
      <path id="XMLID_301_" style={{ fill: '#615E53' }} d="M-.8 1454.6l-17.6-.1-11.3 14.1 2.6 13.5 4.3 8.6 20.6 5.1 12.9-9.9 4.3-18.6z" />
      <path id="XMLID_299_" style={{ fill: '#615E53' }} d="M1420.7 322.9l22.4-14.1 3-27L1432 267l-12.4-7.6-30.2 10.2-8.3 22.9 9.4 26.9z" />
      <path id="XMLID_300_" style={{ fill: '#615E53' }} d="M-255.5-296.7l-26.4-.1-17 21.2 4 20.1 6.3 13 31 7.6 19.3-14.9 6.5-27.8z" />
      <path id="XMLID_302_" style={{ fill: '#615E53' }} d="M-535 831.3h-12.4l-7.9 10.6 1.9 10 3 6.5 14.3 3.8 9-7.4 3.1-13.9z" />
      <path id="XMLID_307_" style={{ fill: '#615E53' }} d="M979.2-260.8l2.6-3.3v-3.7l-2.9-2.4-2.8.6-1.8.9-1.1 4.4 2.1 2.6z" />
      <path id="XMLID_306_" style={{ fill: '#615E53' }} d="M759.9-263.3l.7-1v-1l-.8-.8-.8.2-.6.2-.3 1.3.6.7z" />
      <path id="XMLID_305_" style={{ fill: '#615E53' }} d="M842.4-146.4l-2.6 3.3-.1 3.7 3 2.4 2.8-.6 1.8-.9 1.1-4.2-2.1-2.8z" />
      <path id="XMLID_304_" style={{ fill: '#615E53' }} d="M734.5-231.9l9.5-11.8.1-13.2-10.6-8.4-10 2-6.5 3.1-3.7 15.4 7.4 9.6z" />
      <path id="XMLID_303_" style={{ fill: '#615E53' }} d="M964.7-29l6.2 3.5 6.2-1 3.1-5.6-1.7-4.5-2-2.8-7.5-.5-3.9 4.2z" />
      <path id="XMLID_312_" style={{ fill: '#615E53' }} d="M1134.4 1466.7l-3.2-2.7h-3.8l-2.2 3 .5 2.8.8 1.8 4.4 1.1 2.6-2.1z" />
      <path id="XMLID_311_" style={{ fill: '#615E53' }} d="M1132 1686l-1-.8h-1.1l-.7.9.2.8.3.5 1.2.3.8-.6z" />
      <path id="XMLID_310_" style={{ fill: '#615E53' }} d="M1249 1603.4l3.3 2.7h3.7l2.4-2.9-.6-2.8-.9-1.9-4.4-1-2.6 2.1z" />
      <path id="XMLID_309_" style={{ fill: '#615E53' }} d="M1163.4 1711.3l-11.9-9.5h-13.1l-8.4 10.5 2 10 3.2 6.5 15.3 3.8 9.7-7.4z" />
      <path id="XMLID_308_" style={{ fill: '#615E53' }} d="M1366.4 1481.2l3.5-6.3-1-6.1-5.7-3.2-4.5 1.8-2.7 2-.6 7.5 4.3 3.9z" />
      <path id="XMLID_315_" style={{ fill: '#615E53' }} d="M-668.3 98.5l-3.3-2.7h-3.7l-2.4 2.9.6 2.8.9 1.9 4.3 1 2.7-2z" />
      <path id="XMLID_314_" style={{ fill: '#615E53' }} d="M-553.9 235.2l3.3 2.7h3.7l2.4-3-.6-2.8-.9-1.8-4.3-1.1-2.7 2.1z" />
      <path id="XMLID_313_" style={{ fill: '#615E53' }} d="M-436.5 113l3.5-6.3-1-6.2-5.6-3.1-4.5 1.7-2.8 2-.5 7.5 4.2 3.9z" />
      <path id="XMLID_318_" style={{ fill: '#615E53' }} d="M-415.6 1370.8l2.7-3.3.1-3.7-3-2.4-2.8.6-1.8.9-1.2 4.3 2.2 2.7z" />
      <path id="XMLID_317_" style={{ fill: '#615E53' }} d="M-552.3 1485.2l-2.6 3.4v3.6l2.9 2.4 2.7-.5 1.9-.9 1.1-4.4-2.1-2.6z" />
      <path id="XMLID_316_" style={{ fill: '#615E53' }} d="M-430 1602.7l6.2 3.5 6.2-1.1 3-5.6-1.7-4.5-2-2.8-7.5-.5-3.8 4.2z" />
      <path id="XMLID_23_" style={{ fill: '#615E53' }} d="M307.4 927.4l3.5 2.7h3.5l2.5-3-.5-2.8-1-1.8-4.2-1-2.8 2z" />
      <path id="XMLID_22_" style={{ fill: '#615E53' }} d="M310.1 708.1l1 .8h1.1l.7-.9-.2-.8-.3-.5-1.2-.3-.8.6z" />
      <path id="XMLID_1_" style={{ fill: '#615E53' }} d="M425 805.2l3.4-6.3-1-6.2-5.7-3.1-4.5 1.7-2.8 2.1-.4 7.5 4.2 3.9z" />
      <path id="XMLID_57_" style={{ fill: '#706C5D' }} d="M1266.2 820c2.1 1.9.1 4.7-2.4 3.8-1.2-.6 1.7-1.4 1.6-1.3 1.7-1.7-3.5-.5-3-.6-4.7 1-.2-7.9.7-3.2 0-.1-2.3 1.3-.7 1.8.8-.2 1.6-.5 2.3-.9.7-.1 1.1.1 1.5.4z" />
      <path id="XMLID_53_" style={{ fill: '#706C5D' }} d="M1660.1 674.3c-.5.7 1.7 3.5 2.9 2.2.3-.3.4-.5.4-.5l1.2-.9c-4 9.4 12.6-3.9 2.6-2 2.9-2.3 10.6-5.4 5.9-9.4-.4 0-.7.2-1 .6-.1.3-.3.6-.5.8-.3.3-.6.6-1 .9.2-.9.1-1.6-.4-2.4-5.2-4.9-7.7 6.9-1.7 4.2-2.4 2.2-5.3 5.1-8.4 6.5-.1.2.3-.1 0 0zm8.2.5c.9.3-1.7 2.3-2.3 2.1-.5-.2 1.5-2.4 2.3-2.1zm-.3-8.8c-2.9 2.2-.9-2.5.9-2 .4.8.1 1.5-.9 2-.6.5.6-.4 0 0z" />
      <path id="XMLID_51_" style={{ fill: '#706C5D' }} d="M53.7-575.5c1.1-1.2 2.5-1.3 4.3-.3-2.3-5.7 1.2-8.9 5.5-3.3.8 1 4.3 9.9-.6 7.8.8.4-1.3-10.1-3.3-7.8-1.6 1.9 3.9 4.6 2.8 5.7-2 2-6-.9-6.6-.2-2.7 3 12.4 11.9 11.1-1.9-.3-2.8-3.7-8.1-7.1-7.7-5.8.6-3.2 3.9-5.5 6.5-1.1 1.3-2.5-5 1.2-7.5 4.6-3.1 10.4 0 12.8 4.8 10.1 20.6-23.2 13.6-14.6 3.9z" />
      <path id="XMLID_49_" style={{ fill: '#706C5D' }} d="M422.5-450.1c.4-.1.9 1.3.9 1.4.2-.4.5-.8.7-1.2 2.2-.6 1.1 1.7.5 2.5 2.6.5 2.8 2.3.3 1.7.6.7.8 1.6-.5 1.9-.5.1-.8-.9-.9-1.2-1.4 1.9-2.7.9-1.2-1.4-2.8 0-3-2-.3-1.7-.3-.7-.5-1.1-.5-1.1.2-.4.5-.8 1-.9.2-.1-.5.1 0 0z" />
      <path id="XMLID_42_" style={{ fill: '#706C5D' }} d="M1-325.4c.1.3.1.5 0 .5-.5.4-1.7.2-2.1 0-.6-.2-2.1-4.5-2.3-5-.1-.2 0-.4.1-.5.3-.3 1.2-.5 1.5-.3 1.2.6 2.2 4 2.8 5.3.1.2 0-.1 0 0zm.7 1.5c1 1 1.1 3.7-1.1 2.4-1.2-.7-.4-3.9 1.1-2.4.1.1 0 0 0 0z" />
      <path id="XMLID_24_" style={{ fill: '#706C5D' }} d="M756.7-731.6c.8-.5 5.6.2 5.8 1.2 1 3.9-5.4-1-6.1.5-.8 1.5 2.6 1.7 1.2 3.8-.6.9-2.6-2.3-2.5-3 .1-1.1.6-1.9 1.6-2.5.6-.3-1 .6 0 0zm7.1-.4c.1-.1 1.6 0 1.7.1.1.3 0 .9-.3 1.6-.3.7-3.1.1-1.4-1.7 0-.1-.2.1 0 0z" />
      <path id="XMLID_37_" style={{ fill: '#706C5D' }} d="M1391.4 1057c1 .4 2.7-2.3 2.9-2.4.1.1.1-3.3-.3-3.5-1.1-.2 1.3-3.8 1.6-.2.2 3.2.7 3.6-1.3 5.7-3.7 4.2-3.2.1-2.9.4.3.1 0 0 0 0z" />
      <path id="XMLID_35_" style={{ fill: '#706C5D' }} d="M1388.2 1052.7c-.8-.3 3.1-3.3 3.4-3.5 2.5-2 1.9 2.1.9 1.3-.4-.1-2.8 2.2-2.8 2.1 0-.4.2 5 .5 4.7-2.8 2.2-1.6-3.5-2-4.6-.3-.1.1.4 0 0z" />
      <path id="XMLID_33_" style={{ fill: '#706C5D' }} d="M193.4-193.3c.3.3.4.5.3.8-.2.8-.4 1.9-.7 2.6-.8 3.3-7.9-2.8-8.9-3.5-1.7-1.4 3.2-5.2 2.9-3.3 0 0-.2.8-.7 2.3l4.8 3.3c.2-.5.3-1 .5-1.6.5-.3 1.1-.5 1.8-.6.2.3-.1 0 0 0z" />
      <path id="XMLID_31_" style={{ fill: '#706C5D' }} d="M196.9-197.3c1.8 1.5-3.2 5.5-3 3.7 0 0 .3-.9.8-2.7l-4.8-3.4c-.2.4-.2.8-.4 1.3-.5.4-1.1.6-1.8.7-.3-.3-.4-.5-.3-.8.7-6.1 6.8-1.5 9.5 1.2.1.2-.1 0 0 0z" />
      <path id="XMLID_29_" style={{ fill: '#706C5D' }} d="M334.1-721.9c-.3.5-.5 1-.5 1.6.7-.1 1.4-.4 2.1-.8.3.1 1.2 1.1.8 1.5-.6.7-2.8 1-3.7 1.4-3.3.8-.1-4.8 0-5.1 1.5-.4 1.6.6 1.3 1.4-.1.2 0 0 0 0z" />
      <path id="XMLID_27_" style={{ fill: '#706C5D' }} d="M338.6-726c.4.5.7 1 .9 1.7 0 .2-2.8 5.9-2.9 2.6 0 0 1-2.4.8-1.9-.8-.1-3.8 1.7-2.8-.9-.1.1 3.7-1.7 4-1.5.2.2-.1-.1 0 0z" />
      <path id="XMLID_4_" style={{ fill: '#706C5D' }} d="M-604.6 1261c-.9-1.5-2.1-2.6-3.7-3.3-.5 2.1-.7 4.3-.4 6.5-.6.8-4.4 1.9-5.1.5-1.2-2.5.6-8.8.8-11.7 2-9.8 13.1 5.4 13.7 6.3-.6 4.5-3.3 3.4-5.3 1.7-.4-.5.1.1 0 0z" />
      <path id="XMLID_2_" style={{ fill: '#706C5D' }} d="M-599 1278.1c-1.9.6-3.7.7-5.6.3-.4-.3-12.6-14.8-3.6-11.1.1 0 5.3 5.6 4.2 4.6 1.2-2-.1-12.5 5.8-6.6-.1-.3.2 12.4-.8 12.8-.7.3.4-.2 0 0z" />
      <path id="XMLID_7_" style={{ fill: '#706C5D' }} d="M838 754.1c1.2-.4 2.3 3.5 2.5 3.9.6-1 1.2-2 1.8-3.1 6-1.6 2.9 4.6 1.4 6.7 6.9 1.2 7.4 6.1.9 4.7 1.7 1.8 2 4.4-1.3 5.1-1.3.2-2.1-2.4-2.5-3.3-3.9 5.1-7.3 2.5-3.1-3.6-7.6 0-8-5.3-.9-4.6-.9-1.8-1.3-2.8-1.2-3.1.3-1.4 1.2-2.3 2.4-2.7.5-.2-1.2.4 0 0z" />
    </svg>
  </div>
);