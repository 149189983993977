import React from 'react';

import { AppProps, HomePageText } from '../model';
import Typing from '../components/typing';

import './home-page.sass';

function HomePage(props: AppProps) {
    const data: any = props.data['home'] as HomePageText;

    return (
        <div className="page home-page">
            <h1>
                {data.title.split('|')[0]}
                <span>
                    {/* <span className="red">&lt;</span>{data.title.split('|')[1]}<span className="red">&gt;</span> */}
                    {data.title.split('|')[1]}
                </span>
            </h1>
            <div style={{height: '80px'}}>
                <Typing content={[...data.jobs]} repeat={true} delay={2000} />
            </div>
        </div>
    );
}

export default HomePage;
