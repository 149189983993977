import { WorkItem } from './model';

const JOBS = [
    'Welcome',
    'Bienvenue',
    '안녕하세요',
    'こんにちは',
    'ສະບາຍດີ',
    'From Oustanding Design',
    'To Believable Applications...',
    'I can assist you!',
    'I play Bloody Hurling',
    'And Jazzy Guitar',
    'All of I do is..',
    'Always!!',
    'Always!!!!!!!!!',
    'With a Smile',
    ':)'
];

const WORKS: WorkItem[] = [
    {
        title: 'Project 1',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis numquam fugiat atque fuga aut. Praesentium asperiores eum dolor dignissimos inventore voluptatum adipisci accusamus quidem voluptatibus aliquid nesciunt magni, earum voluptas?',
        links: [{ path: 'https://www.favoritemedium.com', label: 'Favorite Medium' }]
    },
    {
        title: 'Project 2',
        description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab amet unde excepturi cumque dolore quae fuga, recusandae ipsam dolorum deserunt, enim, autem nobis esse sapiente illo voluptas quis obcaecati maxime.',
    }
];

const C = {
    initialData: {
        home: { title: 'Little|Scrawl', jobs: JOBS },
        work: { title: 'Ongoing|Chunks', data: WORKS },
        about: { title: 'A bit|of me', text: `
            I first started my career in the art industry. Graduated from fine Art of Ecole des Beaux Arts de Bordeaux, It only took me a few years as well as a daily {growing interest in programming}, as I was working closely with developers, to switch over that side.

            As a mere designer, {frustration over waiting} integration of my work as well as not always being able to update things myself led me to {self teaching} myself how to code.

            My career {took really off} back from 2014 in Seoul, South Korea where I had the chance to enroll in an international company, allowing me to grow the best of me in terms of rapidness, cultural, knowledge and experience sharing.

            Click {here} to be redirected to my work.

            Having decided to settle ourselves, my wife and I decided to come back to my home country, France, where i'm now based and work for exciting projects on site and remotely for clients all around the world.

            Feel free to drop me a {message} or visit {there} to know where I am on the internet.


            Phetsarath
        ` },
        contact: { title: 'Where to|Find me', github: 'Github', flareNima: 'Flare/ Nima', linkedIn: 'LinkedIn', twitter: 'Twitter', },

        notfound: { title: 'Are you lost?', text: 'Click {here} to go back.' },

        nav: { home: 'Home', about: 'About', work: 'Work', contact: 'Contact' },

        copyrights: 'Little Scrawl © 2020 Phetsarath Phommarinh. All rights reserved.',
    }
};

export default C;
