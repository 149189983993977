import React, { FC, useRef, useState, Suspense, lazy, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { useFirebaseApp, useFirestoreCollection } from 'reactfire';

import AppRoutes from './routes';
import Navigation from './navigation';
import Galaxy from './galaxy';

import useWindowSize from '../utils/windowResize';

import { Lang, AppProps, DataTexts, Size } from '../model';


import C from '../constant';

import './main.sass';

const Frontground = lazy(() => import('./svg/frontground'));
const Flags = lazy(() => import('./svg/flags'));

const Main: FC<RouteComponentProps> = props => {
  const { pathname } = props.location;
  const _currentLocation: string = pathname.replace(/\//g, '');
  const [loaded, updateLoaded] = useState(false);

  const localLang = window.localStorage.getItem('lang') || 'en';
  const [currentLang, updateCurrLang] = useState(localLang);
  const lang: Array<Lang> = [];

  const _appRef: any = useRef();

  const firebaseApp = useFirebaseApp();
  const ref = firebaseApp.firestore();

  const size: Size = useWindowSize();

  let data: DataTexts = C.initialData;

  useFirestoreCollection(
    ref.collection('texts'),
    { startWithValue: [] }
  ).forEach((collection: any) => {
    const db = collection.data();
    if (db.id === currentLang) {
      data = db;
    }
  });

  useFirestoreCollection(
    ref.collection('languages'),
    { startWithValue: [] }
  ).forEach((document: any) => lang.push(document.data()));


  useEffect(() => {
    let timer: any;
    clearTimeout(timer);
    timer = setTimeout(() => {
      updateLoaded(true);
    }, 2500);
    return () => {
        clearTimeout(timer);
    };
  }, [size]);

  const appProps: AppProps = {...props, data, loaded, size };

  return (
    <div ref={_appRef} className={`app ${currentLang} ${_currentLocation}`}>
      <Suspense fallback={<div />}>
        <Frontground />
      </Suspense>

      <div className="wrapper">
        <Suspense fallback={<div />}>
          <Flags data={lang} updateLang={updateCurrLang} currentLang={currentLang}/>
        </Suspense>

        <Navigation {...appProps} />

        <div className="content">
          <AppRoutes {...appProps} />
        </div>

      </div>

      <Galaxy reference={_appRef} />

      <p className="copyright">{data.copyrights}</p>
    </div>
  );
};

export default withRouter(Main);
