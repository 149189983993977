import React, { useEffect } from 'react';
import { animateScroll as scroll } from 'react-scroll';

import { AppProps, NavText } from '../model';

import './navigation.sass';

interface Path {
  path: string;
  id: string;
}

const PATHS: Array<Path> = [
  { path: '/', id: 'home' },
  { path: '/about', id: 'about' },
  { path: '/work', id: 'work' },
  { path: '/contact', id: 'contact' },
];

function Navigation(props: AppProps) {
  // const { pathname } = props.location;
  const data: any = props.data['nav'] as NavText;
  let timer: any;

  function getLabel(id: string): string {
    return data[id];
  }

  function changeRoute({ path, id }: Path): void {
    let delay = 0;
    if (window.innerWidth < 1024) {
      delay = 200;
    }

    scroll.scrollToTop({duration: delay});
    clearTimeout(timer);
    timer = setTimeout(() => {
      props.history.push(path);
    }, delay);
  }

  function createLink() {
    return PATHS.map((path: Path, i: number) => (
      <div key={i} onClick={() => changeRoute(path)} className={path.path === props.location.pathname ? 'active' : ''}>
        {getLabel(path.id)}
      </div>
    ));
  }

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  return <nav>{createLink()}</nav>;
}

export default Navigation;
