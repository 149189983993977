import { useEffect, useState, useCallback } from 'react';
import { animateScroll as scroll } from 'react-scroll';
import { Size } from '../model';

// Hook
function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback((): Size => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
      portraitMode: window.matchMedia("(orientation: portrait)").matches,
    };
  }, [isClient]);

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect((): any => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      scroll.scrollToTop();
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export default useWindowSize;