import React from 'react';
// import { Link } from 'react-router-dom';

import Typing from './../components/typing';

import { AppProps, NotFoundPageText } from './../model';

function NotFoundPage(props: AppProps) {
    const data = props.data['notfound'] as NotFoundPageText;

    let text = '';
    if (data.text) {
        text = data.text
            .replace('{', '<a class="red" style="margin: 0; color: #ef5253;" href="/">')
            .replace('}', '</a>');
    }

    return (
        <div className="not-found-page" style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '60%'}}>
            <Typing content={[data.title]} hideHead={true} type="h1" />
            <p dangerouslySetInnerHTML={{__html: text}} />
        </div>
    );
}

export default NotFoundPage;
