import React, { Suspense, lazy, useRef, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

import { Background } from './svg';
import Loading from './loading';

import './galaxy.sass';

const Planet = lazy(() => import('./svg/planet'));

interface GalaxyProps extends RouteComponentProps {
  reference: any;
}

function Galaxy(props: GalaxyProps) {
  const { pathname } = props.location;
  const _svgContainer: any = useRef();

  // GAP = 20% of the screen / by 2 for its middle
  let GAP: number = (window.innerHeight * .85) / 2;
  const [svgStyle, updateSVGStyle] = useState({
    bottom: `-${GAP}px`,
    transition: 'bottom 500ms ease-out'
  });

  useScrollPosition((controls: any) => {
    const { currPos } = controls;
    const height = props.reference.current.getBoundingClientRect().height;
    const shouldBeStyle = {
      bottom: `-${(height - window.innerHeight + GAP) + currPos.y}px`,
      transition: 'bottom 10ms ease'
    };

    if (JSON.stringify(shouldBeStyle) === JSON.stringify(svgStyle)) return;

    updateSVGStyle(shouldBeStyle);
  }, [svgStyle, props.location]);


  useEffect(() => {
    if (pathname === '/about' || pathname === '/work') {
      const height = props.reference.current.getBoundingClientRect().height;
      const update = {
        bottom: `-${height - window.innerHeight + GAP}px`,
        transition: 'bottom 600ms ease-in'
      };

      updateSVGStyle(update);
    } else {
      updateSVGStyle({
        bottom: `-${GAP}px`,
        transition: 'bottom 500ms ease-out'
      });
    }
  }, [pathname, GAP, props.reference]);

  return (
    <div style={svgStyle} ref={_svgContainer} className="svg-container" >
      <Suspense fallback={<Loading />}>
        <Planet />
      </Suspense>

      <Background />
    </div>
  );
}

export default withRouter(Galaxy);
