import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { render } from 'react-dom';
import { FirebaseAppProvider } from 'reactfire';

import 'firebase/performance';
import 'firebase/firestore';

import './fonts/amaticsc/AmaticSC-Regular.ttf';
import './fonts/josefinsans/JosefinSans-Regular.ttf';
import './index.sass';

import * as serviceWorker from './serviceWorker';

import Main from './components/main';

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,

    appId: process.env.REACT_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

export const root = (
    <FirebaseAppProvider firebaseConfig={firebaseConfig} initPerformance={true}>
        <Router>
            <Main />
        </Router>
    </FirebaseAppProvider>
);

render(
    root,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
