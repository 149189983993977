import React from 'react';
import { AppProps, AboutPageText } from '../model';
import Typing from '../components/typing';

import { formatText } from '../utils/formatter';

import './about-page.sass';

function AboutPage(props: AppProps) {
    const data: any = props.data['about'] as AboutPageText;

    return (
        <div className="page about-page">
            <Typing content={data.title} type="h1" />

            <p dangerouslySetInnerHTML={{ __html: formatText(data.text) }} />
        </div>
    );
}

export default AboutPage;
