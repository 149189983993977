import React from 'react';
import { WorkItem } from '../../model';

function WorkProject({ data }: any) {
  if (!data) return null;

  return data.map(({ title, description, timestamp, images, links }: WorkItem, i: number) => {
      return (
          <div key={i} className="work-item">
              <h3>
                  {title}
                  {timestamp && <small>{new Date(timestamp)}</small>}
              </h3>

              <p>{description}</p>

              {images && <ul>{images.map((image: any, j: number) => <li key={j}><img src={image} alt={`${title}${j} from littlescrawl.com`} /></li>)}</ul>}
              {links && <ul>{links.map(({ path, label }, k: number) => <li key={k}><a href={path} target="_blank" rel="noopener noreferrer">{label}</a></li>)}</ul>}
          </div>
      );
  });
}

export default WorkProject;
